import '../components/News.css'
import Footer from './Footer'
import NewsItem from '../components/NewsItem'
import '../components/News.css'
import {SocialIcon} from "react-social-icons";


const News = (props) => {
    function importAll(r) {
        return r.keys().map(r);
    }

    const images = importAll(require.context('../assets/newsItems', false, /\.(png|jpe?g|svg)$/));

    return (
        <>
            <div className="newsPage">
                <h1>News</h1>
                <br/>
                <ul className="newsItemsList">
                    {
                        images.map((anImage, index) => (
                            <li className="newsItemsListItems" key={index}>
                                <NewsItem path={anImage}></NewsItem>
                            </li>
                        ))
                    }
                </ul>
                <div className="socialMedia">
                    <h2>follow us on</h2>
                    <div className="socialMediaIcons">
                        <SocialIcon className="icons" url="https://www.instagram.com/blackdevilsvorst/" bgColor="#cd1c21"
                                    fgColor="white"/>
                        <SocialIcon className="icons" url="https://www.facebook.com/BlackDevilsVorst" bgColor="#cd1c21"/>
                    </div>
                </div>
            </div>
            <Footer/>

        </>
    );
}
export default News

