import Footer from "./Footer";

const Documents = (props) => {
    return (<>
        <div className="page">
            <h1>NUTTIGE DOCUMENTEN</h1>
            <br/>
            <h2> Medisch certificaat </h2>
            <p>Elke sporter die start bij Basketbal Vlaanderen zal voor de eerste deelname aan een resultaat
                gebonden wedstrijd een medische keuring ondergaan bij een arts. Het attest geldt dan – behoudens de
                arts anders beslist – tot 30 juni van het jaar waarin de sporter de leeftijd van 14 jaar bereikt. Op
                de leeftijd van 14 jaar en 18 jaar – en daarna om de vier jaar, zal de sporter zich opnieuw laten
                keuren door een arts</p>
            <div className="antonMedium">
                <a href=
                       "https://www.basketbal.vlaanderen/documenten/Medisch-Ethisch-Verantwoord-Sporten/ATTEST-MEDISCHE-SCREENING-01072022.pdf">Download
                    attest</a>
            </div>
            <p>
                Seizoen 2023-2024
                Alle spelers en scheidsrechters geboren in 2009 en 2005 zijn verplicht om een (sport)medische keuring,
                bij een sport- of huisarts uit te laten voeren voor aanvang van de competitie 2023-2024. Dit attest is 4
                seizoenen (seizoen 2023-2024 + 3 volgende seizoenen) geldig. Einddatum 30 juni 2027.
                Alle spelers en scheidsrechters die buiten deze geboortejaren vallen én die reeds in seizoen 2021-2022
                of 2022-2023 een medisch attest hebben opgeladen in onze databank moeten zich niet opnieuw medisch laten
                keuren voor seizoen 2023-2024. Hun medisch attest wordt automatisch verlengd en zal gelinkt blijven aan
                het lidmaatschap.
            </p>
            <br/>
            <h2>Tussenkomst mutualiteit</h2>
            <p>
                Er is een jaarlijkse tegemoetkoming mogelijk van je mutualiteit op het lidgeld. Dit bedrag varieert
                volgens de aangesloten mutualiteit.</p>
            <div className="antonMedium">
                <a href="https://www.cm.be/diensten-en-voordelen/vakantie-en-vrije-tijd/sport/sportvereniging">Christelijke
                    mutualiteit</a>
                <a href="https://www.fsmb.be/nl/sportvoordeel">Socialistische mutualiteit</a>
                <a href="https://www.lm-ml.be/nl/voordelen-en-diensten/terugbetaling-sportprikkel">Liberale
                    mutualiteit</a>
                <a href="src/pages/Documents"> Neutrale mutualiteit</a>
            </div>
            <br/>
            <h2>Verzekering</h2>
            <p>Bij het beoefenen van sport op training of tijdens een competitiematch kunnen kwetsuren al eens
                voorkomen. Als lid van de club kan je beroep doen op de sportverzekering van Ethias. In dit kader is het
                belangrijk om ons als club zo snel op de hoogte te brengen zodat we een dossier kunnen opstarten bij
                Ethias voor eventuele medische kosten. Het is belangrijk om deze documenten goed bij te houden.</p>
            <br/>
            <h2>Tutorials</h2>
            <br/>
            <h3>Algemeen</h3>
            <div className="antonMedium">
                <a href="https://www.basketbal.vlaanderen/index.php?p=actions/asset-count/count&id=55576">Spoedcursus
                    basketbal regels</a>
                <a href="https://youtu.be/rcGWPNgX1Bw">Tijdwaarnemer I digitaal scorebord (wedstrijdtafel)</a>
                <a href="https://www.basketbal.vlaanderen/documenten/Shotklok-hulpmiddel.pdf">Shotklokoperator I 24 sec
                    (wedstrijdtafel)</a>
                <a href="https://youtu.be/k1yNcWsvu84">Signalen scheidsrechter</a>
            </div>
            <br/>
            <h3>Digitaal wedstrijdblad</h3>
            <div className="antonMedium">
            <a href="https://youtu.be/qtscjc5K3AE">Online workshop wedstrijdblad (uitgebreid) Basketbal Vlaanderen NL</a>
            <a href="https://www.basketbal.vlaanderen/documenten/Clubondersteuning/Handleiding-DWF-1.pdf">Handleiding wedstrijdblad NL</a>
            <a href="https://youtube.com/playlist?list=PLqoopX-6cUn22pDS5x6qX5Pa7SFSdmQ28">Tutorials wedstrijdblad NL</a>
            <a href="src/pages/Documents?guid=test1">Oefenmodule wedstrijdblad</a>
            </div>
        </div>
        <Footer/>
    </>)
        ;
}
export default Documents