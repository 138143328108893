import {useState} from 'react';
import CompetitionItem from '../components/CompetitionItem';
import '../components/CompetitionItem.css';
import 'react-datepicker/dist/react-datepicker.css';
import '../components/datePicker.css';
import Footer from "./Footer";
import DatePicker from 'react-datepicker';


const Competition = (props) => {
    var dateNow = new Date();
    dateNow.setHours(0,0,0,0);
    const [games, setGames] = useState([]);
    const [teamguid, setTeamGuid] = useState('all');
    const [startFrom, setStartFrom] = useState(dateNow);
    const setTeam = (event) => {
        setTeamGuid(event.target.value);
    }

    const getStartFrom = () => {
      return  startFrom ? startFrom : dateNow
    }

    const dateSortString = (aDateString, aTimeString) => {
       var year = aDateString.substring(6, 10);
       var month = aDateString.substring(3, 5);
       var day = aDateString.substring(0, 2);
        return year + month + day + aTimeString;
    }

    const dateStringToDate = (aString) => {
        console.log()
        var year = aString.substring(6, 10);
        var month = aString.substring(3, 5);
        var day = aString.substring(0, 2);
        return new Date ( month + '-' + day + '-' + year);
    }

    const urlTeamMatches = 'https://vblcb.wisseq.eu/VBLCB_WebService/data/TeamMatchesByGuid?';
    const searchGamesForTeam = async (teamguid) => {
        const responseTeamMatches = await fetch(`${urlTeamMatches}teamguid=BVBL1032${teamguid}`);
        const dataTeamMatches = await responseTeamMatches.json();
        setGames(dataTeamMatches);
    }

    const urlClubMatches = 'https://vblcb.wisseq.eu/VBLCB_WebService/data/OrgMatchesByGuid?issguid=BVBL1032';
    const searchGamesForClub = async () => {
        const responseClubMatches = await fetch(urlClubMatches);
        const dataClubMatches = await responseClubMatches.json();
        setGames(dataClubMatches);
    }

    const searchGames = (teamguid) => {
        teamguid === "all" ? (searchGamesForClub()) : (searchGamesForTeam(teamguid))
    }

    const filterGames = (games) => {
        let arr = [];
        games.forEach((game, i) => {if(dateStringToDate(game.datumString) >= getStartFrom().setHours(0,0,0)) {arr.push(game)}});
        return  arr;
    }


    return (
        <>
            <div className="page">
                <h1>Wedstrijden</h1>
                <br/>
                <div className="containerCompetition">
                    <select className="teamsDropDown" name="teams"
                            onChange={(event) => setTeam(event)}>
                        <option value="HSE++1">HSE A</option>
                        <option value="J21++1">J21 A</option>
                        <option value="J16++1">J16 A</option>
                        <option value="J16++2">J16 B</option>
                        <option value="G14++1">G14 A</option>
                        <option value="all" selected>All</option>
                        {/*<option value="M14++1" text="M16 U16 Meisjes">M16 U16 Meisjes</option>*/}
                    </select>
                    <div className="container-flex">
                    <label className="antonSmall">From:</label>
                    <DatePicker
                        selected={getStartFrom()}
                        onChange={(startFrom) => setStartFrom(startFrom)}
                        openToDate={new Date()}
                    />
                    </div>
                    <button className="buttonGames" onClick={() => searchGames(teamguid)}>toon wedstrijden</button>
                    {
                        games?.length > 0 ? (
                            filterGames(games).sort((a, b) => dateSortString(a.datumString, a.beginTijd).localeCompare(dateSortString(b.datumString, b.beginTijd))).map((game) => (
                                <CompetitionItem game={game}/>
                            ))) : (<h2 className="noGames">NO GAMES FOUND</h2>)
                    }
                </div>
            </div>
            <Footer/>
        </>
    );
}
export default Competition