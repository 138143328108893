import Footer from "./Footer";
import {SocialIcon} from "react-social-icons";

const Contact = (props) => {
    const tenWeyngaert = "https://goo.gl/maps/dsbv5N3AnBVe1aX88";
    const petitPalais = "https://goo.gl/maps/jsH69c8w8igPetYz9";
    const sportzaalUlb = "https://maps.app.goo.gl/zr46bSFcCVum5Ywx6";
    const campusInnova = "https://maps.app.goo.gl/an3vfXAtp2fdoXxe8";
    return (
        <>
            <div className="page">
                <h1>CONTACT</h1>
                <br/>
                <div className="antonBig">BLACK DEVILS BASKETBALCLUB VORST</div>
                <p className="antonMedium"> Van Volxemlaan 325, 1190 Vorst
                    <br/>
                    Tel. +32 (0)474 81 97 09
                    <br/>
                    <a href="mailto:info@blackdevilsvorst.be">info@blackdevilsvorst.be</a>
                    Ondernemingsnummer: BE 0421.034.834
                    <br/>
                    Rekeningnummer: BE49 7340 3778 7771
                    <div className="socialMediaIcons">
                        <SocialIcon className="icons" url="https://www.instagram.com/blackdevilsvorst/"
                                    bgColor="#cd1c21"
                                    fgColor="white"/>
                        <SocialIcon className="icons" url="https://www.facebook.com/BlackDevilsVorst"
                                    bgColor="#cd1c21"/>
                    </div>

                </p>
                <br/>
                <div className="antonBig">Terreinen</div>
                <p className="antonMedium">
                    GC Ten Weyngaert - <a href={tenWeyngaert}>Bondgenotenstraat 54 - 1190 Vorst</a>
                    Petit palais des sports - <a href={petitPalais}>Roosendaelstraat 2, 1190 Vorst</a>
                    Sportzaal ULB Erasmus - <a href={sportzaalUlb}>Lenniksebaan 808, 1070 Anderlecht</a>
                    Campus Innova Lucernacollege - <a href={campusInnova}>Industrielaan 31, 1070 Anderlecht</a>
                </p>
            </div>
            <Footer/>
        </>
    )
        ;
}
export default Contact