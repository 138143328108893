import './Teams.css'

const TrainingDayInfo = (props) => {
    return (
        <>
                            <span>{props?.day} {props?.startTime} - {props?.endTime}</span>
                            <span> - {props?.location}</span>
        </>
    );
}
export default TrainingDayInfo