import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Header from '../src/components/Header';
import News from './pages/News';
import About from './pages/About';
import Teams from './pages/Teams';
import Competition from './pages/Competition';
import Contact from './pages/Contact';
import Documents from './pages/Documents';


function App() {
    return (
        <>

            <BrowserRouter>
                <div className="navBarContainer">
                    <Header/>
                </div>
                <div className="containerPage">
                    <Routes>
                        <Route path='/' element={<News/>}/>
                        <Route path='/about' element={<About/>}/>
                        <Route path='/teams' element={<Teams/>}/>
                        <Route path='/competition' element={<Competition/>}/>
                        <Route path='/contact' element={<Contact/>}/>
                        <Route path='/documents' element={<Documents/>}/>
                    </Routes>
                </div>
            </BrowserRouter>

        </>

    );
}

export default App;
