import './Teams.css'
import TrainingDayInfo from "./TrainingDayInfo";

const Team = (props) => {
    return (
        <>
            <div className="teamContainer">
                <div className="team">
                    <div className="teamImageContainer">
                        <div className="teamName">{props.name}</div>
                        <img className="teamImage" src={props.image} alt="BXL"/>
                        <div className="teamTrainingHours">
                            <h4>trainingsuren:</h4>
                            <div className="coach">coach: {props.coach}</div>
                            <ul>
                                {
                                    props.trainingDays.map((day, index) => (
                                        <li className="trainingDays" key={index}>
                                            <TrainingDayInfo day={day.day}
                                                             startTime={day.startTime}
                                                             endTime={day.endTime}
                                                             location={day.location}/>
                                        </li>))

                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Team