import brusselLogo from '../assets/brussel_logo.svg'
import vorstLogo from '../assets/gemeenteVorst_logo.svg'
import vgcLogo from '../assets/vgc_logo.svg'

const Footer = (props) => {
    return (
        <>
            <div className="containerFooter">
                <div className="footer">
                    <img className="footerLogos" src={brusselLogo} alt="BXL"/>
                    <img className="footerLogos" src={vorstLogo} alt="VORST"/>
                    <img className="footerLogos" src={vgcLogo} alt="VGC"/>
                </div>
            </div>
        </>
    );
}
export default Footer