import Team from '../components/Team'
import '../components/Teams.css'
import Footer from "./Footer";
import teamsInfo from '../assets/teams/teamsInfo.json'

const Teams = (props) => {

    return (
        <>
            <div className="page">
                <h1>Ploegen</h1>
                <ul className="teamList">
                    {
                        teamsInfo.teams.map((team, index) => (
                            <li className="teamListItems" key={index}><Team
                                image={require('../assets/teams/' + team.image)}
                                name={team.name}
                                coach={team.coach}
                                trainingDays={team.trainingDays}
                                ></Team>
                            </li>
                        ))
                    }
                </ul>
            </div>
            <Footer/>
        </>
    )
        ;
}
export default Teams