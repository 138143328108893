const CompetitionItem = ({game}) => {
    return (
        <>
            <div className="competitionItemContainer">
                <p>
                    <section className="homeVSaway">
                        <span className="teamHome">{game?.tTNaam}</span>
                        <span className="antonMedium">VS</span>
                        <span className="teamAway">{game?.tUNaam}</span>
                    </section>
                    <br></br>
                    <span className="attribute">Datum :</span> <span
                    className="attributeValue">{game?.datumString}</span>
                    <br></br>
                    <span className="attribute">waar :</span> <span className="attributeValue"> {game?.accNaam}</span>
                    <br></br>
                    <span className="attribute">start :</span> <span
                    className="attributeValue"> {game?.beginTijd}</span>
                    <br></br>
                    <span className="attribute">uitslag :</span> <span
                    className="attributeValue"> {game?.uitslag}</span>
                </p>
            </div>
        </>
    );
}
export default CompetitionItem