import Footer from "./Footer";

const About = (props) => {

    return (<>
        <div className="page">
            <h1>OVER DE CLUB</h1>
            <br/>
            <h2> BESTUUR </h2>
            <p>Luc Daelemans (voorzitter):&nbsp;
                <a href="mailto:luc.daelemans@vites.be">luc.daelemans@vites.be</a>
            </p>
            <p>Ruth Flikschuh (secretaris):&nbsp;
                <a href="mailto:info@blackdevilsvorst.be">info@blackdevilsvorst.be</a>
            </p>
            <p>Robin Flikschuh (penningmeester):&nbsp;
                <a href="mailto:robin@blackdevilsvorst.be">robin@blackdevilsvorst.be</a>
            </p>
            <p>Tristan Lagae (ondervoorzitter):&nbsp;
                <a href="mailto:tristan@blackdevilsvorst.be">tristan@blackdevilsvorst.be</a>
            </p>
            <p>Baptist Bosmans (vertrouwenspersoon):&nbsp;
                <a href="mailto:baptistbos@gmail.com">baptistbos@gmail.com</a>
            </p>
            <br/>
            <h2> CLUBFILOSOFIE </h2>
            <p> FAIR PLAY – Fair Play is één van de basiswaarden voor Black Devils: we gaan voor eerlijk en fair
                spel met gelijkwaardigheid voor eenieder en respect voor ieders kunnen en taak op en naast het
                veld</p>
            <p> DEELNAME - "Deelnemen is belangrijker dan winnen" is vooral bij de jeugd onze drijfveer. Daarnaast
                stimuleren we deelname aan het hele basket-gebeuren door spelers, ouders en vrienden, van truitjes
                wassen tot taken als officials of coaching </p>

            <p> OPLEIDING JEUGD - De jeugdwerking is de ruggengraat van onze club. We hopen dat jeugd kan
                doorgroeien tot de ambitieuze herenploegen en zo tot fantastische basket-resultaten kan leiden. </p>

            <p> PLOEGGEEST - Niemand zal een wedstrijd alleen winnen, de hele ploeg speelt een rol, op en naast de
                bank, als supporter of op het veld. </p>
            <br/>
            <h2>VRIJWILLIGERSBELEID: IEDEREEN SAMEN MAAKT DE BLACK DEVILS</h2>
            <p>Zonder vrijwilligerswerking zal en kan Black Devils Vorst niet functioneren. Het bestuur is zich
                bewust dat engagement verschillend kan zijn, afhankelijk van tijd, mogelijkheden en kennis. Ouders
                voelen zich betrokken omdat ze zien dat hun kinderen plezier beleven, volwassen spelers en coach
                engageren zich om hun kennis over te dragen omdat ze zelf van het spel houden. De club werkt aan een
                structureel beleid voor ondersteuning, stimulering en begeleiding van haar vrijwilligers.
                <br></br>
                <br></br>
                We onderstrepen de positieve energie van vrijwilligers door het opvolgen en waarderen van élke
                bijdrage : vervoer van spelers, taken aan ‘de tafel’, cafetaria maar ook communicatietaken en
                organisatorisch werk zoals ploegverantwoordelijke, scheidsrechters, coaches, bestuur enz.
                <br></br>
                <br></br>
                Ook onze jongeren zijn méér dan enkel spelers. We moedigen hen aan om rollen op te nemen als
                jeugdscheidsrechters, als (hulp-) coach, voor de opbouw van de zaal, aan ‘tafel’ en voor
                festiviteiten. Zonder de vrijwillige inzet van zovelen is er geen Black Devils Club en wordt matchen
                spelen onmogelijk.
                <br></br>
                <br></br>
                We organiseren regelmatig een cursus ‘tafelmanieren’ (invullen van wedstrijdtaken zoals 24 sec,
                chrono en blad), benadrukken het belang van vrijwilligerswerk bij de inschrijving in de club en
                geven vrijwilligers het nodige vertrouwen.
                <br/>
                <br/>
                <h2>Black Devils Vorst ondertekend Panathlon Verklaring</h2>
                De Panathlon Verklaring (“Ethiek in de Jeugdsport”) stelt dat “sport en spel mensen verbindt”. Black
                Devils Vorst wil ook de positieve waarden in de jeugdsport voorop stellen. Concreet betekent dit:
                Alle kinderen hebben het recht
                bij het sport beoefenen zich te amuseren, te spelen en te rusten
                deel te nemen aan training die aangepast is aan hun leeftijd, individueel ritme en mogelijkheden
                getraind en begeleid te worden door competente mensen
                zich te meten met kinderen van hetzelfde niveau in een aangepaste competitie,
                In veilige omstandigheden aan sport te doen
                de kans te krijgen kampioen te worden, of het niet te worden
                Meer info op de website:
                <a className="antonMedium"
                   href="http://panathlonvlaanderen.be/verklaring">http://panathlonvlaanderen.be/verklaring</a>
            </p>
        </div>
        <Footer/>
    </>);
}
export default About