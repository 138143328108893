import './News.css'

const NewsItem = (props) => {
    return (
        <>
            <div className="newsItemContainer">
                        <object className="newsItemImage" aria-label="news item" data={props.path}/>
            </div>
        </>
    );
}
export default NewsItem